/*-------------------------
         Count up
-------------------------*/
.count-up {
  margin: 4rem 0;
  padding: 32px 16px;
}

.count-number {
  font-size: 1.5rem;
  font-weight: 500;
}

.count-content {
  margin: 10px 0;
}

.count-icon i {
  font-size: 2.5rem;
}
