/*-------------------------
          CONTACT
-------------------------*/
::-moz-placeholder, .form-control::-moz-placeholder {
  color: #5f5f5f;
}

.contact .form-item .form-control {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  height: 44px;
  font-size: 14px;
  position: relative;
  transition: border .9s ease;
  padding: 1rem;
  border: none;
}

.contact .form-item .form-group {
  background-color: #f1f3f6;
  box-shadow: inset 3px 3px 3px #dadada, inset -3px -3px 3px #fafafa;
  -webkit-box-shadow: inset 3px 3px 3px #dadada, inset -3px -3px 3px #fafafa;
  -moz-box-shadow: inset 3px 3px 3px #dadada, inset -3px -3px 3px #fafafa;
  -o-box-shadow: inset 3px 3px 3px #dadada, inset -3px -3px 3px #fafafa;
  -ms-box-shadow: inset 3px 3px 3px #dadada, inset -3px -3px 3px #fafafa;
  border-radius: 1.25rem;
}

.contact-form textarea {
  min-height: 175px;
  resize: none;
}

.contact #message.toast {
  max-width: 500px;
  padding: 2px 0 1px;
  position: absolute;
  top: -2px;
  display: inline-block;
  left: 200px;
  border-radius: 1rem;
  color: #fafafa;
}

.contact .toast button span {
  position: relative;
  top: 8px;
}

.contact .contact-info ul li {
  margin-top: 1.5rem;
}

.contact .contact-info i {
  margin-right: 0.5rem;
}

.contact .contact-info img {
  width: 50px;
}
