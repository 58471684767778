@import "colors/pink";

.cookie-banner-wrapper {
  width: 100%;
  max-width: 575px;
  padding: 20px 20px;
  color: #ffffff;
  font-size: 0.85rem;
  z-index: 10000;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: $pink-color;
  border-radius: 1.25rem 0 0 0;
  box-shadow: 3px 3px 3px #cdcdcd, -3px -3px 3px #fafafa;

  .cookie-banner-link, .cookie-banner-link:hover, .cookie-banner-link:active, .cookie-banner-link:focus {
    text-decoration: none;
    color: #2c2d30;
  }

  .cookie-banner-button {
    height: 32px;
    display: inline-block;
    background-color: #f1f3f6;
    text-align: center;
    color: $pink-color !important;
    border-radius: 0.75rem;
    transition: all 0.3s ease;
    box-shadow: 1px 1px 1px #cdcdcd, -1px -1px 1px #fafafa;

    &.secondary {
      color: #2c2d30 !important;
    }

    &:hover {
      box-shadow: inset 3px 3px 3px #cdcdcd, inset -3px -3px 3px #fafafa;
    }

    i {
      font-size: 1.25rem;
      line-height: 32px;
      vertical-align: middle;
      position: relative;
      top: 50%;
      margin: 0 .5rem 0 0.75rem;
      transform: translateY(-50%);
      transition: all 0.3s ease;
    }

    span {
      line-height: 32px;
      margin-right: 0.75rem;
      display: inline-block;
      vertical-align: middle;
      font-weight: bold;
    }
  }

  .cookie-link {
    color: inherit;

    &:hover {
      color: #2c2d30;
    }
  }
}

.cookie-modal {

  &.show {

    .modal-dialog {
      transform: scaleY(1);
      height: auto;
    }
  }

  .modal-dialog {
    position: fixed;
    bottom: 0;
    max-width: 100%;
    width: 100%;
    margin: 0;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform 0.5s ease-in-out;
    height: 0;
  }

  .modal-content {
    background-color: #f1f3f6;
  }

  .close {
    color: #5f5f5f;
    text-shadow: none;
  }

  .pill-button {
    cursor: pointer;
    z-index: 100000;
  }
}

.cookie-banner-divider {
  border-top: 1px solid #dee2e6;
  width: 80%;
}

.cookie-item {
  margin-bottom: 1rem;

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $pink-color;
    background-color: $pink-color;
  }

  .control-wrapper {
    display: flex;
    align-items: center;

    .cookie-item-title {
      flex: 1;
    }

    .cookie-item-controller {
      font-size: 1.5rem;
      margin-left: 10px;
      padding: 5px;
      cursor: pointer;
    }
  }

  .cookie-item-content {
    font-size: 0.9rem;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.35s ease-in-out;
    height: 100%;
    max-height: 0;

    &.active {
      transform: scaleY(1);
      max-height: 200px;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .cookie-banner-wrapper {
    //background-color: #2c2d30;
    box-shadow: 3px 3px 3px #202125, -3px -3px 3px #3e3e3e;

    .cookie-banner-button {
      background-color: #2c2d30;
      box-shadow: 1px 1px 1px #202125, -1px -1px 1px #3e3e3e;

      &.secondary {
        color: #f1f3f6 !important;
      }

      &:hover {
        box-shadow: inset 3px 3px 3px #202125, inset -3px -3px 3px #3e3e3e;
      }
    }
  }
}


@media (max-width: 575px) {

  .cookie-banner-wrapper {
    border-radius: 0;
  }

}

