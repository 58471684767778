/*-------------------------
      SINGLE Portfolio
-------------------------*/
.portfolio-single .modal-content {
  background-color: #f1f3f6;
}

.portfolio-single .close {
  color: #5f5f5f;
  text-shadow: none;
}

.portfolio-single {
  .carousel-indicators li {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
}

.portfolio-single img {
  border-radius: 1rem;
  width: 100%;
}

.portfolio-single {
  .additional-info .website i {
    font-size: 1.5rem;
    color: $pink-color;
  }
}
