/*-------------------------
      Flat Demo Light
-------------------------*/
.flat-demo #main > section {
  background-color: #f8f8f8;
}

.flat-demo .blog-single .modal-content, .flat-demo .portfolio-single .modal-content, .flat-demo .cookie-modal .modal-content {
  background-color: #f8f8f8;
}

.flat-demo header {
  background-color: #fafafa;
}

.flat-demo .testimonial-image-border {
  background-color: #f8f8f8;
}

.flat-demo .testimonial-image-border img {
  padding: 4px;
  background-color: transparent;
  border: 1px solid #d2d2d2;
  max-width: 100%;
  height: auto;
}

.flat-demo .skill-box .skillbar {
  background-color: #e1e1e1;
}

.flat-demo .menu li a {
  box-shadow: none;
}

.flat-demo .menu li a:hover, .flat-demo .menu li a.active {
  box-shadow: none;
}

.flat-demo .button-border {
  box-shadow: none;
  padding: 0;
}

.flat-demo .pill-button:hover, .flat-demo .pill-button.active, .flat-demo .testimonial .owl-dot.active span,
.flat-demo .skill-box .skillbar, .flat-demo .blog-intro, .flat-demo .contact .form-item .form-group,
.flat-demo .testimonial .owl-carousel .testimonial-image-border {
  box-shadow: none;
}

.flat-demo .pill-button, .flat-demo .portfolio-filter .pill-button.active, .flat-demo .portfolio-filter .pill-button:hover {
  color: #fafafa;
}

.flat-demo .image-border {
  box-shadow: none;
  background-color: transparent;
  padding: 0;
}

.flat-demo .box-border, .flat-demo .box-hover-border, .flat-demo .contact .form-item .form-group {
  background-color: #fefefe;
  box-shadow: none;
}

.flat-demo .testimonial .owl-carousel .testimonial-image::after, .flat-demo .testimonial .owl-carousel .testimonial-image::before,
.flat-demo .blog-item::before, .flat-demo .blog-image::after {
  display: none;
}

.flat-demo .testimonial .owl-carousel .testimonial-image, .flat-demo .portfolio-filter .button-border {
  background-color: transparent;
}

.flat-demo .portfolio-filter .pill-button {
  color: #3c3c3c;
}

.flat-demo .blog-image {
  background-color: #f8f8f8;
  padding: 0;
}

/*-------------------------
      Flat Demo Dark
-------------------------*/
.arshia-dark.flat-demo .box-hover-border:hover {
  box-shadow: none;
}

.arshia-dark.flat-demo header, .arshia-dark.flat-demo .testimonial-image-border, .arshia-dark.flat-demo .blog-image, .arshia-dark.flat-demo .blog-single .modal-content, .arshia-dark.flat-demo .portfolio-single .modal-content, .arshia-dark.flat-demo .cookie-modal .modal-content {
  background-color: #2c2d30;
}

.arshia-dark.flat-demo .testimonial-image-border img {
  border: 1px solid #3c3c3c;
}

.arshia-dark.flat-demo .box-border, .arshia-dark.flat-demo .box-hover-border, .arshia-dark.flat-demo .contact .form-item .form-group {
  background-color: rgba(52, 53, 57, 0.9);
}

.arshia-dark.flat-demo .box-hover-border:hover {
  box-shadow: none;
}

.arshia-dark.flat-demo .portfolio-filter .pill-button {
  color: #fafafa;
}

/*-------------------------
         Flat Mono
-------------------------*/
.mono.flat-demo .pill-button {
  background-color: #5f5f5f;
  border: 1px solid #5f5f5f;
  color: #fafafa !important;
}

.mono.flat-demo .pill-button:hover {
  background-color: #3c3c3c;
  border: 1px solid #3c3c3c;
}

.mono.flat-demo .portfolio-filter .pill-button {
  background-color: transparent;
  border: none;
  color: #3c3c3c !important;
}

.mono.flat-demo .portfolio-filter .pill-button.active, .mono.flat-demo .portfolio-filter .pill-button:hover {
  color: #fafafa !important;
  background-color: #3c3c3c;
}

/*-------------------------
     Dark Flat Mono
-------------------------*/
.arshia-dark.mono.flat-demo .pill-button {
  background-color: #fafafa;
  border: 1px solid #fafafa;
  color: #5f5f5f !important;
}

.arshia-dark.mono.flat-demo .pill-button:hover {
  background-color: #dedede;
  border: 1px solid;
}

.arshia-dark.mono.flat-demo .portfolio-filter .pill-button {
  background-color: transparent;
  border: none;
  color: #d2d2d2 !important;
}

.arshia-dark.mono.flat-demo .portfolio-filter .pill-button.active, .arshia-dark.mono.flat-demo .portfolio-filter .pill-button:hover {
  background-color: #dedede;
  color: #5f5f5f !important;
}

.arshia-dark.mono.flat-demo .skill-box .skillbar {
  background-color: #3c3c3c !important;
}
