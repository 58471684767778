/*-------------------------
        Work SKILL
-------------------------*/
.skill-box {
  padding: 60px 30px 30px;
  margin-top: 30px;
}

.skill-box .skillbar {
  position: relative;
  display: block;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  transition: 0.1s linear;
  background-color: #f1f3f6;
  box-shadow: inset 3px 3px 8px rgba(55, 84, 170, 0.3), inset -3px -3px 8px white;
}

.skill-box .skillbar:not(:last-child) {
  margin-bottom: 43px;
}

.skill-box .skillbar-title {
  position: absolute;
  top: -28px;
  left: -17px;
  font-weight: 600;
  font-size: 14px;
}

.skill-box .skillbar-title span {
  margin-left: 18px;
}

.skill-box .fill-skillbar {
  height: 10px;
  width: 0;
  border-radius: 5px;
  float: left;
}

.skill-box .skill-bar-percent {
  position: absolute;
  font-weight: 500;
  bottom: 16px;
  right: 0;
}

/*-------------------------
      Language Skill
-------------------------*/
.language-bar {
  padding: 35px 30px;
  margin-top: 30px;
}

.language-skill:not(:last-child) {
  margin-bottom: 15px;
}

.language-skill h4 {
  font-size: 16px;
}

.language-skill h4 span {
  color: #5f5f5f;
  font-size: 14px;
  font-weight: normal;
}

/*-------------------------
      Awards
-------------------------*/
.awards-box {
  padding: 35px 30px;
  margin-top: 30px;
}

.awards-box:not(:last-child) {
  margin-bottom: 15px;
}

.awards-item {
  display: flex;
  padding-left: 0;

  .award-icon {
    margin-right: 10px;
  }

  .award-description {
    line-height: 2.25rem;
    flex: 1;
  }

  .award-extras {
    margin-right: auto;
    text-align: center;
    font-size: 0.8rem;
  }
}

.award-divider {
  width: 70%;
  margin: 1.25rem auto;
  border-top: 2px solid $pink-color;

}
