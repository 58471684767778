/*-------------------------
        Testimonial
-------------------------*/
.testimonial .owl-carousel {
  margin-top: 30px;
}

.testimonial .owl-carousel .testimonial-item {
  padding: 0 30px 30px;
}

.testimonial .owl-carousel .testimonial-item img {
  width: 85px;
  display: inline-block;
  border-radius: 50%;
}

.testimonial .owl-carousel .testimonial-item:hover .testimonial-border {
  width: 90px;
}

.testimonial .owl-carousel .owl-stage-outer {
  padding: 1rem 0;
}

.testimonial .owl-carousel .testimonial-image {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  padding: 10px;
  margin-top: -60px;
  background-color: #f1f3f6;
  width: 115px;
  height: 115px;
}

.testimonial .owl-carousel .testimonial-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 2px;
  width: 92%;
  height: 40%;
  background-color: #f1f3f6;
  z-index: 1;
  right: 6px;
}

.testimonial .owl-carousel .testimonial-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 3px 3px 3px #dadada, inset -3px -3px 3px #fafafa;
  border-radius: 50%;
  z-index: 0;
  left: 2px;
}

.testimonial .owl-carousel .testimonial-image .img-thumbnail {
  background-color: transparent;
}

.testimonial .owl-carousel .testimonial-image-border {
  box-shadow: 3px 3px 3px #dadada, -3px -3px 3px #fafafa;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  z-index: 2;
}

.testimonial .owl-carousel .owl-item {
  padding-top: 2rem;
}

.testimonial .owl-carousel .owl-dots {
  text-align: center;
  margin-top: 1rem;
}

.testimonial .owl-carousel .owl-dot span {
  display: block;
  width: 24px;
  height: 5px;
  background-color: #e1e1e1;
  margin: 0 0.25rem;
  border-radius: 1rem;
  transition: all 0.3s ease;
}

.testimonial .owl-carousel .owl-dot.active span {
  width: 32px;
  box-shadow: 3px 3px 8px rgba(55, 84, 170, 0.1), -3px -3px 8px white;
}
