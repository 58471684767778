/*-------------------------
          About
-------------------------*/
.about .personal-info ul li {
  margin-bottom: 1.25rem;
}

.about .personal-info ul li p {
  font-weight: 500;
}

.about .personal-info span {
  font-weight: 300;
  color: #5f5f5f;
}
