/*-------------------------
         RESUME
-------------------------*/
.resume h3 i {
  padding-right: 0.75rem;
}

.resume .timeline-items {
  padding: 30px;
  margin-top: 30px;
}

.resume .timeline-item {
  position: relative;
  margin-bottom: 30px;
}

.resume .timeline-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  width: 1px;
  border-radius: 1rem;
  display: block;
}

.resume .timeline-contents {
  padding-left: 30px;
}

.resume .timeline-icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: -7px;
  z-index: 10;
}

.resume .timeline-year {
  font-size: 14px;
  font-weight: 500;
}
