/*-------------------------
       BLOG SIDEBAR
-------------------------*/
.post-sidebar {
  position: fixed;
  display: block;
  width: 310px;
  right: -320px;
  padding: 0;
  top: 0;
  height: 100%;
  float: none;
  z-index: 1041;
  border-radius: 16px 0 0 16px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all .66s ease-in-out;
  -moz-transition: all .66s ease-in-out;
  -o-transition: all .66s ease-in-out;
  -ms-transition: all .66s ease-in-out;
  transition: all .66s ease-in-out;
}
.post-sidebar.open {
  right: 0;
}
.post-sidebar-content {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  box-shadow: 3px 3px 3px #cdcdcd, -3px -3px 3px #fafafa;
  background-color: #f1f3f6;
  z-index: 1;
}
.post-sidebar-toggle{
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 46px;
  text-align: center;
  background-color: #f1f3f6;
  right: 75px;
  font-size: 19px;
  top: 81px;
  border-radius: 0.75rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 3px 3px 3px #cdcdcd, -3px -3px 3px #fafafa;
}
.post-sidebar-toggle span {
  display: block;
  position: absolute;
  height: 3px;
  width: 44%;
  background: #000;
  border-radius: 5px;
  opacity: 1;
  left: 13px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.post-sidebar-toggle span:nth-child(1) {
  top: 12px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.post-sidebar-toggle span:nth-child(2) {
  top: 19px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.post-sidebar-toggle span:nth-child(3) {
  top: 26px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.post-sidebar-toggle span:before {
  content: '';
  width: 3px;
  height: 3px;
  background-color: #000;
  position: absolute;
  left: -4px;
  border-radius: 5px;
}
.post-sidebar-toggle.open span{
  left: 14px;
}
.post-sidebar-toggle.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 14px;
}
.post-sidebar-toggle.open span:before {
  left: -2px;
}
.post-sidebar-toggle.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.post-sidebar-toggle.open span:before {
  left: -2px;
}
.post-sidebar-toggle.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 25px;
}
.post-sidebar-toggle.open span:before {
  left: -2px;
}
.aside-contents {
  margin: 0 15px 15px;
  box-shadow: 3px 3px 3px #cdcdcd, -3px -3px 3px #fafafa;
  background-color: #f1f3f6;
  border-radius: 16px;
  padding: 15px;
}
.search{
  padding: 15px;
}
.search .form-group {
  background-color: #f1f3f6;
  box-shadow: inset 3px 3px 3px #dadada, inset -3px -3px 3px #fafafa;
  border-radius: 1.5rem;
  margin: 0;
}
.search .form-group input {
  border: none !important;
  background-color: transparent;
  padding: 13px 16px 11px;
  font-size: 14px;
  outline: none !important;
  width: 88%;
}
.search .form-group button{
  position: absolute;
  right: 6px;
  top: 3px;
}
.aside-title {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}
.aside-title span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  font-weight: 600;
}
.aside-title:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 1px;
  top: 62%;
  position: absolute;
  background-color: #ddd;
}
.aside-item ul li{
  position: relative;
}
.aside-item ul li a {
  font-size: 14px;
  color: #444;
  padding: 4px 0px;
  display: inline-block;
  padding-left: 12px;
  transition: all 0.3s ease;
}
.aside-item ul li a:hover{
  padding-left: 17px;
}
.aside-item ul li:last-child a {
  padding-bottom: 0;
}
.aside-item ul li i{
  font-size: 8px;
  position: absolute;
  top: 10px;
  width: 12px;
  display: inline-block;
}
/* flat */
.flat-demo .post-sidebar-toggle, .flat-demo .search .form-group, .flat-demo .aside-contents{
  background-color: #fefefe;
  box-shadow: 0 0 3px 0px rgba(145, 145, 145, 0.3) !important;
}
.flat-demo .post-sidebar-content{
  background-color: #f8f8f8 !important;
  box-shadow: 0 0 8px 0px rgba(145, 145, 145, 0.3) !important;
}
.flat-demo .comments-devider{
  height: 1px;
}
/* media */
@media (max-width: 1199px){
  .post-sidebar-toggle{
    transform: none;
    transition: none;
    right: 65px
  }
  .post-sidebar-toggle.open{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 0;
  }
  .post-sidebar-toggle.open span{
    display: none;
  }
  .blog-single .post-sidebar-toggle{
    right: 65px;
  }
  .color-switcher .pallet-button{
    right: 15px;
  }
}
